<template>
  <base-page-layout title="Contas">
    <v-row no-gutters justify="end" class="mb-4">
      <v-col cols="auto" class="ml-2">
        <export-to-excel filename="accounts" :fields="fields" :fetch-fn="loadingExportItems" />
      </v-col>
      <v-col cols="auto">
        <mf-button color="primary" label="Criar conta" icon="add" @click="create"></mf-button>
      </v-col>
    </v-row>
    <mf-card-container
      v-model="search"
      placeholder="Busca (nome, acrônimo, Id externo, ERP e id do varejista)"
      title=" Listagem de Contas"
      description="
        Listagem de todas as contas cadastradas. Realize buscas por nome da conta, acrônimo, Id externo, ERP e id do varejista nesta seção da plataforma.
      "
      use-search
    >
      <template #headers>
        <v-row no-gutters justify="end">
          <v-col cols="auto">
            <v-menu v-model="menu" left offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-badge class="mr-4" color="primary" :value="filtersQuantity" :content="filtersQuantity" overlap>
                  <v-btn icon v-on="on">
                    <v-icon x-large>filter_list</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-card width="285px" class="pa-4">
                <v-select
                  v-model="selectedStat"
                  class="mb-0 pb-3"
                  hide-details
                  :items="accountStats"
                  label="Situação"
                  outlined
                  @change="refetchAdminAccounts"
                />
                <v-select v-model="selectedDagStat" class="mb-0 pb-3" hide-details :items="dagStats" label="Status" outlined @change="refetchAdminAccounts" />
                <v-select
                  v-model="selectedIntegration"
                  class="mb-0 pb-3"
                  hide-details
                  :items="integrationTypes"
                  label="Integração"
                  outlined
                  @change="refetchAdminAccounts"
                />
                <v-switch v-model="showInactiveAccounts" class="mt-0 pt-0" label="Apenas contas ativas" @change="refetchAdminAccounts" />
                <v-switch v-model="showEcommerceOnly" class="mt-0 pt-0" label="Apenas contas e-commerce" @change="refetchAdminAccounts" />
                <v-switch v-model="showCbmOnly" class="mt-0 pt-0" label="Apenas contas CBM" @change="refetchAdminAccounts" />
                <v-btn class="mx-auto mt-4" color="primary" outlined @click="cleanFilters">Limpar Filtros</v-btn>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <v-row justify="end" no-gutters>
        <v-col class="mt-n2" cols="auto">
          <headers-data-table :headers.sync="headers" :default-headers="getDefaultHeadersOptions()" headers-settings-name="headers-list-account" />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :loading="$apollo.queries.adminAccounts.loading"
            :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
            :server-items-length="maxRecords"
            :options.sync="options"
            :headers="computedHeaders"
            :items="accounts"
            :search="search"
          >
            <template v-slot:[`item.id_external`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ item.id_external }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ item.name }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.erp.name`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ item.erp.name }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.id_client`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ item.id_client ? item.id_client : '-' }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.integration_type`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon class="mr-2" x-small :color="integrationColor(item.integration_type)">mdi-brightness-1</v-icon>
                  {{ formatIntegration(item.integration_type) }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.status_reporter.max_date`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ item.active && item.status_reporter ? formatStatusReporter(item.status_reporter) : '-' }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.status_reporter`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon class="mr-2" x-small :color="statusColor(item.status_reporter.status || 'Desativado')">mdi-brightness-1</v-icon
                  >{{ item.status_reporter.status || 'Desativado' }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.status_account`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon class="mr-2" x-small :color="tagColor(accountTag(item.status_account))">mdi-brightness-1</v-icon>{{ accountTag(item.status_account) }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.dag.scheduled_time`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ item.dag.scheduled_time }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.dag.units`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ item.units }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.is_ecommerce`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon class="mr-2" x-small :color="ecommerceColor(item.is_ecommerce)">mdi-brightness-1</v-icon>
                  {{ item.is_ecommerce }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.is_cbm`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon class="mr-2" x-small :color="ecommerceColor(item.is_cbm)">mdi-brightness-1</v-icon>
                  {{ item.is_cbm }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.dag`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-btn color="primary" small outlined text @click="openDag(item)"
                    ><img width="12" height="12" class="mr-2" :src="require('@/assets/icons/airflow.png')" alt="airflow" /> Dag</v-btn
                  >
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.configurations`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-menu :close-on-content-click="false">
                    <template #activator="{ on }">
                      <v-btn class="ml-2" icon v-on="on">
                        <mf-icon>more_vert</mf-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="redirect(item._id)">
                        <v-list-item-title>Ver detalhes </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="copyText(item.db_name)">
                        <v-list-item-title>Copiar db_name </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-layout>
              </v-flex>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </mf-card-container>
  </base-page-layout>
</template>

<script>
import { cronToTime } from '@/helpers/time'
import { QUERY_GET_ACCOUNTS_PAGINATED, QUERY_GET_ACCOUNT_UNITS, QUERY_GET_DATA_ACCOUNT } from '@/modules/accounts/graphql'
import { cloneDeep } from 'lodash'

const defaultHeaders = [
  {
    text: 'ID Ext.',
    value: 'id_external',
    align: 'center',
    width: '8%',
    isActive: true
  },
  {
    text: 'ID Varejista',
    value: 'id_client',
    align: 'center',
    sortable: false,
    width: '8%',
    isActive: true
  },
  {
    text: 'Nome',
    value: 'name',
    align: 'center',
    width: '10%',
    isActive: true
  },
  {
    text: 'ERP',
    value: 'erp.name',
    align: 'center',
    width: '10%',
    isActive: true
  },
  {
    text: 'Última atualização',
    value: 'status_reporter.max_date',
    align: 'center',
    width: '10%',
    isActive: true
  },
  {
    text: 'Status',
    value: 'status_reporter',
    align: 'center',
    sortable: false,
    width: '8%',
    isActive: true
  },
  {
    text: 'Situação',
    value: 'status_account',
    sortable: false,
    align: 'center',
    width: '8%',
    isActive: true
  },
  {
    text: 'Integração',
    value: 'integration_type',
    sortable: false,
    align: 'center',
    width: '8%',
    isActive: true
  },
  {
    text: 'Agendamento',
    value: 'dag.scheduled_time',
    align: 'center',
    width: '9%',
    isActive: true
  },
  {
    text: 'Lojas',
    value: 'units',
    align: 'center',
    width: '8%',
    isActive: true
  },
  {
    text: 'Ecommerce',
    value: 'is_ecommerce',
    align: 'center',
    width: '8%',
    isActive: false
  },
  {
    text: 'CBM',
    value: 'is_cbm',
    align: 'center',
    width: '8%',
    isActive: false
  },
  {
    text: 'Airflow',
    value: 'dag',
    align: 'center',
    width: '8%',
    isActive: true
  },
  {
    text: '',
    value: 'configurations',
    sortable: false,
    align: 'center',
    width: '2%',
    isActive: true,
    isRequired: true
  }
]

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    ExportToExcel: () => import('@/components/imports/ExportToExcel.vue'),
    HeadersDataTable: () => import('../../components/atomic-components/molecules/HeadersDataTable')
  },
  data: () => ({
    accounts: [],
    search: '',
    options: {},
    maxRecords: 0,
    showInactiveAccounts: false,
    accountStats: [
      'Implantando',
      'Concluído',
      'Churn',
      'Suporte Impossível',
      'Teste',
      'Churn Temporário',
      'Em ajuste',
      'Plataforma Antiga',
      'Sem Status',
      'Aguardando lançamento'
    ],
    selectedStat: '',
    dagStats: ['Atualizado', 'Desatualizado', 'Agendado', 'Processando', 'Integrando', 'Desativado'],
    selectedDagStat: '',
    integrationTypes: ['PDV', 'Retaguarda', 'Híbrido', 'Não detectado'],
    selectedIntegration: '',
    menu: false,
    filtersQuantity: 0,
    fields: {
      ID_Externo: 'id_external',
      Nome: 'name',
      ERP: 'erp.name',
      Ultima_Atualização: 'status_reporter_date',
      Status: 'status_reporter_status',
      Situação: 'status_account',
      Integração: 'integration_type',
      Agendamento: 'dag.scheduled_time',
      Lojas: 'units',
      Ecommerce: 'is_ecommerce',
      Cbm: 'is_cbm',
      DB_Name: 'db_name'
    },
    showEcommerceOnly: false,
    showCbmOnly: false,
    accountUnits: {},
    headers: []
  }),
  computed: {
    computedHeaders() {
      const sizeWithoutCodeAndProduct = 100 - (24 + 8.5)
      const headers = this.headers.filter(header => header.isActive)
      const activableHeadersSize = headers.filter(header => !header.isRequired).length
      const result = headers.map(header => {
        return {
          ...header,
          width: header.width ? header.width : `${sizeWithoutCodeAndProduct / activableHeadersSize}%`
        }
      })
      return result
    },
    context() {
      return {
        uri: this.$microservicesUrls['accounts'],
        headers: {
          authorization: localStorage.getItem('session_id')
        }
      }
    },
    filters() {
      return Object.assign(
        {},
        this.showInactiveAccounts ? { active: true, status: this.selectedStat } : { status: this.selectedStat },
        this.selectedIntegration !== '' ? { integration: this.selectedIntegration } : null,
        this.selectedDagStat !== '' ? { dagStatus: this.selectedDagStat } : null,
        this.showEcommerceOnly ? { onlyEcommerce: true } : null,
        this.showCbmOnly ? { onlyCbm: true } : null
      )
    }
  },
  apollo: {
    adminAccountsUnits: {
      query: QUERY_GET_ACCOUNT_UNITS,
      update(data) {
        data.adminAccountsUnits.map(unit => {
          this.accountUnits[unit.account_id] = unit.units
        })
      }
    },
    adminAccounts: {
      query: QUERY_GET_ACCOUNTS_PAGINATED,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          {
            input: this.filters
          },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      update({ getAccounts }) {
        getAccounts.data.forEach(account => {
          account.dag.scheduled_time = cronToTime(account?.dag?.schedule_interval)
          if (!account.status_reporter) account.status_reporter = {}
          if ((account.status_reporter.status === 'Desativado' || !account.active) && account.flags?.ecommerce?.active) {
            account.status_reporter = { status: 'Atualizado' }
          }
          account.flags?.ecommerce?.active ? (account.is_ecommerce = 'Sim') : (account.is_ecommerce = 'Não')
          account.flags?.cbm?.active ? (account.is_cbm = 'Sim') : (account.is_cbm = 'Não')
          account.units = this.accountUnits[account._id]
        })
        this.maxRecords = getAccounts.count
        this.accounts = getAccounts.data
      },
      context() {
        return this.context
      }
    }
  },
  mounted() {
    if (this.headers.length < 1) this.headers = cloneDeep(defaultHeaders)
  },
  methods: {
    redirect(id) {
      this.$router.push(`/accounts/${id}/edit/geral`)
    },
    getDefaultHeadersOptions() {
      return cloneDeep(defaultHeaders)
    },
    create() {
      this.$router.push(`/accounts/create`)
    },
    statusColor(value) {
      if (value === 'Atualizado') return '#20c997'
      if (value === 'Desatualizado') return '#ff6b6b'
      if (value === 'Agendado') return '#339af0'
      if (value === 'Processando') return '#cc5de8'
      if (value === 'Suporte impossível') return '#adb5bd'
      if (value === 'Integrando') return '#f06595'
      return '#868e96'
    },
    tagColor(value) {
      const mapColors = {
        Implantando: '#339af0',
        Concluído: '#20c997',
        Churn: '#ff6b6b',
        Teste: '#339af0',
        'Suporte Impossível': '#adb5bd',
        'Churn Temporário': '#FFF176',
        'Em ajuste': '#339af0',
        'Sem Status': '#adb5bd',
        'Plataforma Antiga': '#adb5bd',
        'Aguardando lançamento': '#f0b433'
      }

      return mapColors[value] || mapColors['Sem Status']
    },
    integrationColor(value) {
      const mapColors = {
        full_pos: '#339af0',
        backoffice: '#20c997',
        hybrid: '#FFF176'
      }
      return mapColors[value] || '#adb5bd'
    },
    ecommerceColor(value) {
      if (value === 'Sim') return '#20c997'
      return '#ff6b6b'
    },
    accountTag(status) {
      if (status?.deploying) return 'Implantando'
      if (status?.done) return 'Concluído'
      if (status?.churn) return 'Churn'
      if (status?.impossible_support) return 'Suporte Impossível'
      if (status?.test) return 'Teste'
      if (status?.temporary_churn) return 'Churn Temporário'
      if (status?.adjusting) return 'Em ajuste'
      if (status?.deprecated) return 'Plataforma Antiga'
      if (status?.waiting_release) return 'Aguardando lançamento'
      else return 'Sem Status'
    },
    formatStatusReporter(status_reporter) {
      const date = status_reporter?.max_date
      const lastDagCompletion = status_reporter?.last_dag_completion
      const drift = status_reporter?.drift

      if (!date && !lastDagCompletion) return '-'

      const days = this.$moment().diff(this.$moment(date, 'YYYY-MM-DD'), 'days')
      const dx = `D-${drift || days}`

      const maxDate = this.$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      const lastUpdate = this.$moment(lastDagCompletion, 'YYYY-MM-DD').format('DD/MM/YYYY')

      return `${dx} | ${drift < days ? lastUpdate : maxDate}`
    },
    openDag(acc) {
      let dagName = acc.db_name
      switch (acc?.dag?.tag) {
        case 'prod':
          break
        case 'beta':
          dagName += '_test'
          break
        case 'alpha':
          dagName += '_alpha'
          break
      }
      return window.open(`https://airflow-cluster.mercafacil.com/home?search=dag_${dagName}`, '_blank')
    },
    refetchAdminAccounts() {
      let filters = 0
      if (this.showInactiveAccounts) filters += 1
      if (this.selectedStat !== '') filters += 1
      if (this.selectedDagStat !== '') filters += 1
      if (this.selectedIntegration !== '') filters += 1
      this.filtersQuantity = filters
      this.$apollo.queries.adminAccounts.refetch()
    },
    cleanFilters() {
      this.showInactiveAccounts = false
      this.selectedStat = ''
      this.selectedDagStat = ''
      this.selectedIntegration = ''
      this.filtersQuantity = 0
    },
    formatIntegration(integration) {
      if (integration === 'full_pos') return 'PDV'
      if (integration === 'backoffice') return 'Retaguarda'
      if (integration === 'hybrid') return 'Híbrido'
      return 'Não detectado'
    },
    copyText(db_name) {
      try {
        navigator.clipboard.writeText(db_name)
      } catch (err) {
        console.log(err.message)
      }
    },
    async loadingExportItems() {
      try {
        const variables = Object.assign(
          {},
          { page: this.options.page || 1 },
          { pageSize: 99999 },
          this.options.sortBy ? { sort: this.options.sortBy } : { sort: ['_id'] },
          { sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC')) },
          this.search ? { search: this.search } : null,
          !this.showInactiveAccounts ? { status: this.selectedStat } : { active: true, status: this.selectedStat },
          this.selectedIntegration !== '' ? { integration: this.selectedIntegration } : null,
          this.selectedDagStat !== '' ? { dagStatus: this.selectedDagStat } : null
        )
        const result = await this.$apollo.query({
          query: QUERY_GET_DATA_ACCOUNT,
          fetchPolicy: 'no-cache',
          variables
        })

        let filteredResult = result.data.adminAccountsFiltered.data

        filteredResult = await Promise.all(
          filteredResult.map(item => {
            if (!item.status_reporter) item.status_reporter = {}

            if (item.active && item.status_reporter) {
              item.status_reporter_date = this.formatStatusReporter(item.status_reporter.max_date)
            } else {
              item.status_reporter_date = '-'
            }

            if (item.status_reporter.status) {
              item.status_reporter_status = item.status_reporter.status
            } else {
              item.status_reporter_status = 'Desativado'
            }

            if ((item.status_reporter_status === 'Desativado' || !item.active) && item.flags?.ecommerce?.active) {
              item.status_reporter_status = 'Atualizado'
            }

            item.dag.scheduled_time = cronToTime(item.dag?.schedule_interval)
            item.integration_type = this.formatIntegration(item.integration_type)
            item.status_account = this.accountTag(item.status_account)
            item.units = this.accountUnits[item._id]

            if (item.flags?.ecommerce?.active) {
              item.is_ecommerce = 'Sim'
            } else {
              item.is_ecommerce = 'Não'
            }

            if (item.flags?.cbm?.active) {
              item.is_cbm = 'Sim'
            } else {
              item.is_cbm = 'Não'
            }
            return item
          })
        )
        return filteredResult
      } catch (err) {
        console.log(err)
        this.$alert({
          alert_message: 'Erro ao exportar dados.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
</style>
